import { SVGProps } from "react";

const LoaderCircleSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={42} height={42} viewBox="0 0 42 42" fill="none" {...props}>
    <path
      d="M21.01 41.3C17.4533 41.3047 13.9578 40.3749 10.8735 38.6037C7.78928 36.8324 5.22453 34.2819 3.43611 31.2076C1.64768 28.1332 0.698361 24.643 0.683238 21.0863C0.668114 17.5297 1.58771 14.0315 3.34992 10.942C5.11214 7.85261 7.65513 5.28041 10.7242 3.483C13.7933 1.68559 17.2808 0.726085 20.8374 0.70056C24.3939 0.675035 27.8948 1.58438 30.9894 3.33755C34.084 5.09073 36.6636 7.62618 38.47 10.69C38.5523 10.8032 38.6099 10.9323 38.6392 11.0692C38.6684 11.206 38.6686 11.3475 38.6398 11.4844C38.6109 11.6213 38.5537 11.7506 38.4717 11.864C38.3897 11.9774 38.2849 12.0723 38.1639 12.1427C38.0429 12.213 37.9085 12.2572 37.7694 12.2723C37.6303 12.2874 37.4896 12.2732 37.3563 12.2306C37.2231 12.1879 37.1002 12.1177 36.9958 12.0246C36.8914 11.9315 36.8076 11.8175 36.75 11.69C35.1036 8.90665 32.7482 6.6099 29.9243 5.03406C27.1004 3.45821 23.909 2.65968 20.6757 2.71997C17.4424 2.78026 14.283 3.6972 11.5198 5.37723C8.75665 7.05725 6.48855 9.44023 4.94703 12.283C3.40551 15.1258 2.64574 18.3267 2.74519 21.559C2.84464 24.7913 3.79979 27.9394 5.51316 30.682C7.22654 33.4246 9.6368 35.6637 12.4981 37.1707C15.3593 38.6776 18.5691 39.3986 21.8 39.26C22.0652 39.26 22.3196 39.3653 22.5071 39.5529C22.6947 39.7404 22.8 39.9948 22.8 40.26C22.8 40.5252 22.6947 40.7796 22.5071 40.9671C22.3196 41.1546 22.0652 41.26 21.8 41.26L21.01 41.3Z"
      fill="#81D4FA"
    />
    <path
      d="M30.83 38.6C30.6564 38.6 30.4859 38.5548 30.3351 38.4689C30.1843 38.383 30.0585 38.2593 29.97 38.11C29.8348 37.8819 29.7957 37.6095 29.8613 37.3525C29.927 37.0956 30.0919 36.8753 30.32 36.74C34.3583 34.3533 37.3233 30.5039 38.6 25.99C39.1289 24.1179 39.3584 22.1739 39.28 20.23C39.2681 19.9648 39.362 19.7057 39.5411 19.5097C39.7202 19.3137 39.9698 19.1969 40.235 19.185C40.5002 19.1731 40.7593 19.267 40.9553 19.4461C41.1513 19.6252 41.2681 19.8748 41.28 20.14C41.4275 23.8059 40.5817 27.4436 38.832 30.6684C37.0823 33.8932 34.4938 36.5853 31.34 38.46C31.1856 38.5516 31.0095 38.6 30.83 38.6Z"
      fill="#81D4FA"
    />
  </svg>
);

export default LoaderCircleSmallIcon;
