import * as date from "./date.utils";
import * as error from "./error.utils";
import * as form from "./form.utils";
import * as languages from "./language.utils";
import * as licenses from "./licenses.utils";
import * as toast from "./toast.utils";

export * from "./date.utils";
export * from "./error.utils";
export * from "./form.utils";
export * from "./language.utils";
export * from "./licenses.utils";
export * from "./toast.utils";

const utils = {
  licenses,
  toast,
  form,
  languages,
  date,
  error,
};

export default utils;
