export const contentLicenses = {
  en: {
    version: "Version 1.0",
    copyright: "Copyright © 2022 Vasco Electronics",
    appName: "VASCO AUDIENCE",
    title: "Terms and conditions of the Vasco Audience",
    sections: {
      preliminary: {
        title: "I. PRELIMINARY",
        items: [
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis bibendum suscipit nunc tortor turpis nunc. Porta ac non, placerat non magna vel. Volutpat, amet pharetra adipiscing nec tortor vel hendrerit.",
          "Euismod consequat nulla sit consequat pellentesque nulla morbi pellentesque. Nulla quisque faucibus blandit vulputate. Sed mi neque vestibulum ac sed congue. Massa nulla ut feugiat volutpat, eu diam. Dolor sit proin digniss Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerc",
        ],
        paragraphs: [
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu.",
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu.",
        ],
      },
      services: {
        title: "SERVICES",
        items: [
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis bibendum suscipit nunc tortor turpis nunc. Porta ac non, placerat non magna vel. Volutpat, amet pharetra adipiscing nec tortor vel hendrerit.",
          "Euismod consequat nulla sit consequat pellentesque nulla morbi pellentesque. Nulla quisque faucibus blandit vulputate. Sed mi neque vestibulum ac sed congue. Massa nulla ut feugiat volutpat, eu diam. Dolor sit proin digniss Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerc",
        ],
        paragraphs: [
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu.",
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu.",
        ],
      },
    },
  },
  pl: {
    version: "Wersja 1.0",
    copyright: "Copyright © 2022 Vasco Electronics",
    title: "Warunki i zasady Vasco Audience",
    appName: "VASCO AUDIENCE",
    sections: {
      preliminary: {
        title: "I. WSTĘP",
        items: [
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis bibendum suscipit nunc tortor turpis nunc. Porta ac non, placerat non magna vel. Volutpat, amet pharetra adipiscing nec tortor vel hendrerit.",
          "Euismod consequat nulla sit consequat pellentesque nulla morbi pellentesque. Nulla quisque faucibus blandit vulputate. Sed mi neque vestibulum ac sed congue. Massa nulla ut feugiat volutpat, eu diam. Dolor sit proin digniss Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerc",
        ],
        paragraphs: [
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu.",
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu.",
        ],
      },
      services: {
        title: "USŁUGI",
        items: [
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis bibendum suscipit nunc tortor turpis nunc. Porta ac non, placerat non magna vel. Volutpat, amet pharetra adipiscing nec tortor vel hendrerit.",
          "Euismod consequat nulla sit consequat pellentesque nulla morbi pellentesque. Nulla quisque faucibus blandit vulputate. Sed mi neque vestibulum ac sed congue. Massa nulla ut feugiat volutpat, eu diam. Dolor sit proin digniss Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerc",
        ],
        paragraphs: [
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu.",
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu.",
        ],
      },
    },
  },
};

export type LicenseContentKeys = keyof typeof contentLicenses;
