import { PropsWithChildren } from "react";

import { LoaderCircleBigIcon, LoaderCircleSmallIcon } from "~/icons";

export const Loader = ({ children }: PropsWithChildren) => {
  return (
    <div className="fixed bg-black bg-opacity-60 z-10 top-0 left-0 w-full h-full flex items-center justify-center flex-col">
      <div className="relative flex items-center justify-center">
        <div className="animate-spin">
          <LoaderCircleBigIcon />
        </div>
        <div className="animate-spin-reverse absolute">
          <LoaderCircleSmallIcon />
        </div>
      </div>
      <p className="text-m mt-4">{children}</p>
    </div>
  );
};
