import * as Sentry from "@sentry/remix";

// NOTE can be used for test purposes, works only on client side
export const capture = (error: unknown) => {
  // NOTE Display nice error titles in Sentry
  if (error instanceof Error) {
    error.name = error.message;
  }
  Sentry.captureException(error);
  console.error(error);
};
