import { SVGProps } from "react";

const LoaderCircleBigIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" {...props}>
    <path
      d="M28 56C22.5197 55.9987 17.1602 54.3892 12.5859 51.371C8.01165 48.3527 4.42366 44.0585 2.26666 39.0206C0.109664 33.9826 -0.521508 28.4224 0.451417 23.0291C1.42434 17.6359 3.95858 12.6467 7.73999 8.67998C7.83323 8.58149 7.94496 8.50235 8.06879 8.44704C8.19262 8.39172 8.32613 8.36133 8.4617 8.35762C8.59727 8.35391 8.73224 8.37692 8.85892 8.42537C8.98559 8.47382 9.10149 8.54676 9.19998 8.64C9.29847 8.73324 9.37764 8.84496 9.43295 8.9688C9.48827 9.09263 9.51866 9.22614 9.52237 9.36171C9.52608 9.49728 9.50307 9.63225 9.45462 9.75893C9.40617 9.8856 9.33323 10.0015 9.23999 10.1C6.06465 13.4303 3.83005 17.5438 2.76437 22.0202C1.6987 26.4966 1.84011 31.1757 3.1742 35.5796C4.50828 39.9835 6.98724 43.9544 10.3579 47.0869C13.7286 50.2194 17.8702 52.4013 22.3599 53.4096C26.8496 54.418 31.5265 54.2168 35.9129 52.8265C40.2994 51.4363 44.2384 48.9067 47.3275 45.4963C50.4167 42.0859 52.5454 37.9167 53.4963 33.4145C54.4472 28.9123 54.1862 24.2383 52.74 19.87C52.6564 19.618 52.6764 19.3432 52.7955 19.106C52.9146 18.8687 53.123 18.6885 53.375 18.605C53.627 18.5215 53.9018 18.5414 54.139 18.6605C54.3762 18.7796 54.5564 18.988 54.64 19.24C56.0331 23.448 56.4073 27.9265 55.7317 32.3073C55.0562 36.6881 53.3502 40.8459 50.7542 44.4387C48.1582 48.0315 44.7463 50.9566 40.7992 52.9735C36.8521 54.9904 32.4825 56.0414 28.05 56.04L28 56Z"
      fill="#81D4FA"
    />
    <path
      d="M46.61 9.44001C46.3525 9.43898 46.1054 9.33869 45.92 9.16001C42.5126 5.91726 38.2893 3.65965 33.7001 2.62769C29.1108 1.59573 24.3276 1.8281 19.86 3.30003C19.6112 3.37103 19.3446 3.34282 19.1161 3.22135C18.8877 3.09988 18.7152 2.8946 18.6349 2.64863C18.5546 2.40265 18.5729 2.13513 18.6857 1.90229C18.7985 1.66945 18.9972 1.48941 19.24 1.4C24.0506 -0.184352 29.2007 -0.434235 34.1421 0.676948C39.0834 1.78813 43.6308 4.21877 47.3 7.71C47.4469 7.84747 47.5488 8.02603 47.5926 8.22236C47.6364 8.41869 47.62 8.62366 47.5454 8.81049C47.4709 8.99733 47.3418 9.15736 47.1749 9.26966C47.008 9.38197 46.8112 9.44133 46.61 9.44001V9.44001Z"
      fill="#81D4FA"
    />
  </svg>
);

export default LoaderCircleBigIcon;
