import toast from "react-hot-toast";

export const show = (message: string, customMarginBottom?: number) => {
  toast.dismiss(); // NOTE Hide previous toast

  toast(message, {
    style: {
      marginBottom: customMarginBottom || 0,
    },
  });
};
